<script lang="ts" setup>
import { createColumnHelper } from '@tanstack/vue-table'
import type { ITokenDetail } from '@settings'
import type { TanstackTableColumn } from '@ui/components/TanstackTable'

const { findRoleById } = useApiRoles()
const { data: invitedUsers, isPending } = useApiUserManagementGetInvited()

const { getDivisionById } = useDivisions()

function trim(str: string | undefined, trimLength: number) {
  if (str) {
    const charArray = Array.from(str)
    const trimmedArray = charArray.slice(0, trimLength)
    const trimmedString = trimmedArray.join('')
    return charArray.length > trimLength ? trimmedString + '...' : str
  }
}

const columnHelper = createColumnHelper<ITokenDetail>()
const tableHeaders = [
  columnHelper.accessor('token.data.Email', {
    id: 'email',
    header: 'Email',
  }),
  columnHelper.accessor('token.data.Permissions', {
    id: 'companyId',
    header: 'Division',
    cell: ({ getValue }) => {
      const permissions = getValue()
      const companies = permissions.slice(0, 6)

      return { companies, permissions }
    },
  }),
  columnHelper.accessor('token.data.Permissions', {
    id: 'permissions',
    header: 'Permissions',
    cell: ({ getValue }) => title(findRoleById(getValue()[0].RoleId)?.name),
  }),
  columnHelper.accessor('status', {
    header: 'status',
    cell: ({ getValue }) =>
      getValue()
        .replace(/([A-Z]+)/g, ' $1')
        .toLocaleLowerCase(),
  }),
]
</script>

<template>
  <TanstackTable
    v-if="
      $can('manage', 'InvitedUsers') && invitedUsers?.invitations?.length != 0
    "
    :columns="tableHeaders"
    :data="invitedUsers?.invitations"
    :loading="isPending"
  >
    <template #item-email="{ value }: TanstackTableColumn<ITokenDetail>">
      <span>
        <div class="flex flex-col">
          {{ value }}
        </div>
      </span>
    </template>

    <template
      #item-companyId="{
        value: { companies, permissions },
      }: TanstackTableColumn<ITokenDetail>"
    >
      <span class="flex flex-col gap-2">
        <span v-for="company in companies" :key="company.id" class="text-sm">
          {{ trim(getDivisionById(company.CompanyId)?.name, 30) }}
        </span>
        <span v-if="permissions.length > 6" class="mb-2 text-xs text-gray-500">
          and
          {{ permissions.length - 6 }}
          more
        </span>
      </span>
    </template>

    <template #item-permissions="{ value }: TanstackTableColumn<ITokenDetail>">
      <span class="flex flex-wrap gap-4 py-2">
        {{ value }}
      </span>
    </template>

    <template #item-status="{ value }: TanstackTableColumn<ITokenDetail>">
      <span>
        {{ value }}
      </span>
    </template>
  </TanstackTable>
</template>
